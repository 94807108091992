import { NavLink } from "react-router-dom";

import cpLogo from "../../assets/cp-logo.jpeg";

const Navbar = () => {
  return (
    <header>
      <div className=" fixed top-0 left-0 right-0 z-30  overflow-hidden bg-[#010030] shadow-header">
        <div aria-label="Top" className=" mx-2   ">
          <div className="lg:flex items-center justify-between h-16">
            <div className="flex h-full w-full items-center justify-between">
              <div className="  flex w-full h-full 	 overflow-hidden  lg:ml-0 ">
                <NavLink to="/">
                  <img
                    className="   h-full     object-cover"
                    src={cpLogo}
                    alt="THE PROXY SHOP"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
